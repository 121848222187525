
/*
<div class="carousel carousel-slider" style="width: 100%;">

 <ul class="control-dots">
 <li class="dot selected" value="0" role="button" tabindex="0" aria-label="slide item 1"></li>
 <li class="dot" value="1" role="button" tabindex="0" aria-label="slide item 2"></li>
 <li class="dot" value="2" role="button" tabindex="0" aria-label="slide item 3"></li>
 </ul>

 <button type="button" aria-label="previous slide / item" class="control-arrow control-prev control-disabled">
 </button>
 <div class="slider-wrapper axis-horizontal">
 <ul class="slider animated" style="transform: translate3d(0px, 0px, 0px); transition-duration: 350ms;">
 <li class="slide selected previous"><div><img src="/1.jpg"><p class="legend">Legend 1</p></div></li>
 <li class="slide"><div><img src="/2.jpg"><p class="legend">Legend 2</p></div></li>
 <li class="slide"><div><img src="/3.jpg"><p class="legend">Legend 3</p></div></li>
 </ul></div><button type="button" aria-label="next slide / item" class="control-arrow control-next"></button>
 <p class="carousel-status">1 of 3</p></div>
 */
 .carousel-slider img
{
    /* height: 300px !important; */
    height: auto !important;
}
 .carousel-slider
{
    /* height: 300px !important; */
    height: auto !important;
}
.carousel-style
{
    /* height: 500px !important; */
    height: auto !important;
}

.legend:hover
{
  color: rgb(255, 251, 0)  !important;
}

.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 5px 5px 5px 5px;
  margin-right: 20px;

  min-width: 200px !important;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.Card .Button
{
     cursor: pointer; 
}

.cardcollection1
{
width: 100%;
display: flex;
flex-direction: row;
flex-wrap:wrap;
justify-content: center;
}

#ktbl{
    visibility: hidden; 
}
.moduleSection{
    background-color: rgba(84, 78, 78);
    color: aqua;
    border: 1px solid #ccc;
    border-collapse: collapse;
    border-radius: 6px;
    width: 100%;    
    visibility:visible !important;
} 
 
.hid{
   visibility:hidden;
} 
.the_td{
    width: 75px;
}
.the_td_sm{
    width: 60px;
}

.the_code{
    margin-left: 10px;
    width: 55px;
}
.the_select1{
    width: 55px; 
}
.the_select2{
    width: 55px; 
}
.the_select3{
    width: 75px; 
}
.only_two
{
width:  100% ;
display: flex;
justify-content:space-evenly;
}
.one_cat
{
    width:  80% ;
    margin-top:  20px  ;
    margin-bottom: 20px;
}
.one_of_two
{
    width:  45% ;
}
.rrow
{
    width: 100%;
    display: flex;
    justify-content: center;
}

.discount
{
    color:red;
    border-radius: 50%;
    background-color: rgb(234, 233, 233);
    width: 80px;
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    direction: rtl;
}
 
.price
{
    direction: rtl;
    color:rgb(53, 197, 27);
    border-radius: 50%;
    background-color: rgb(234, 233, 233);
    width: 80px;
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

}
.discountcat
{
    color:red;
    border-radius: 50%;
    background-color: rgb(234, 233, 233);
   
    width: 60px;
    height: 60px;
    font-size: 10px;
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    direction: rtl;
}
 
.pricecat
{
    direction: rtl;
    color:rgb(53, 197, 27);
    border-radius: 50%;
    background-color: rgb(234, 233, 233);
    
    width: 60px;
    height: 60px;
    font-size: 10px;
   
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

}