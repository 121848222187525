.footer1{
  background-color: orange;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center;
}
.footer1_1{
  padding: 10px;
  width: 250px;
  display: flex;
  flex-direction: column;
}.footer1_2{
  padding: 10px;
  width: 250px;
  display: flex;
  flex-direction: column;
}.footer1_3{
  padding: 10px;
  width: 250px;
  display: flex;
  flex-direction: column;
}
.footer1_s1{
  font-size: 16px;
  font-weight: 300;
}
.footer1_s2{
  font-size: 12px;
  font-weight: 100;
}
hr
{
  height:2px;
  border-width:0;
  color:gray;
  background-color:gray;
}
.above0_grid{
  font-size: 16px;
  height: 22px;
}

.above1_grid{
  background-color: rgb(29, 93, 79);
  color: antiquewhite;  
  display: grid;
  grid-template-columns: auto auto auto;
  
}

.grid-item {  
  text-align: center;
}
@media only screen and (max-width: 600px) {

 #tobehidden {
   display: none;
  }
  .above0_grid{
    font-size: 12px;
    height: 22px;
  }

}

a{
  color: antiquewhite;  
  margin-left: 10px;
  margin-right: 10px;
}




