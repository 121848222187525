.App {
  text-align: center;
  background-color: #FAFAFA;
 /* height: 2000px;     */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(68, 71, 73);
 min-height: 100vh; 
 
  
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: calc(10px + 2vmin) !important;
  color: white !important;
}
.App-header2 {
  background-color: rgb(68, 71, 73);
/*  min-height: 200vh; 
 margin-top: 500px;*/
  
  display: flex !important;
  flex-direction: column !important;
  align-items: center!important;
  justify-content:space-between !important;
  font-size: calc(10px + 2vmin) !important;
  color: white !important;
}


.button-box
{
  width: 100%;
  padding: 10px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px;
}
.button-box-sub
{
  width: 100%;
  padding:  10px;
  display: flex !important;

  gap: 10px;
}

.sub-centre{
 /* align-items: center !important;*/
  justify-content: center !important;
}
.sub-left{
  
  justify-content: left !important;
}


.dashboard-button
{
  width: 300px;
  height: 80px !important;
  border-radius: 20px;
  color: aqua;
  background-color: gray;
  font-size: 25px;
}

.App-link {
  color: #61dafb;
}

h3{
  padding-right: 10px;
}

img{
  height: 80px;
  margin-left: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label_i{
color: aliceblue;
top:10px;
margin-left: 0px;
}
.text_i{
  height: 35px;
  width: 200px;
  border-radius: 10px;
  color: black;
  }
  input:disabled {
   color: rgb(221, 255, 0);
   font-size: 20px;
   background-color: rgba(169, 169, 169, 0.077);
   text-align: center;
}
select{
  height: 35px;
  width: 150px;
  font-size: 20px;
}
.text_area_i
{
  height: 500px;
  width: 350px;
  font-size: 20px;
  border-radius: 10px;
}
.text_area_ii
{
  height: 200px;
  width: 350px;
  font-size: 20px;
  border-radius: 10px;
}
#first_row
{
  top:0px;
}
#leftbox1
{
  text-align: left;
}
.space{
  width: 100%;
  height: 200px;
}
#hid{
   color: rgb(68, 71, 73);
}
select{
  border-radius: 10px;
}
textarea{
  border-radius: 10px;
}
#sign
{
  border-radius: 10px;
}
.loading-image {
  position: absolute;
  width: 150px !important;
  height: 150px;
  top: 150px;
  left:50%;
 
  z-index: 100;
}
.loading-none {
  width: 150px !important;
  height: 150px;
  display: none;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
  z-index: 1;
}
.table1{
  direction: ltr;
  width: 100%;
  border: 2px solid;
  border-color: aqua;
  border-collapse: collapse;
}
.rowh
{
  border: 2px solid;
  border-color: rgb(255, 89, 0);
  border-collapse: collapse;
  
}
td{
  border: 2px solid;
  border-color: rgb(255, 234, 0);
  border-collapse: collapse;
  
}

.srch{
  width: 150px;
  height:40px;
  border-radius: 10px;
}
.srchbtn
{
   width: 40px;
  height:40px;
  border-radius: 20px;
  background-color: blue;
}
.srchbtn:hover {
  background-color: rgb(255, 145, 0);
}
.thep
{
  margin-top: 10px;
  font-size: 12px;
  display:'flex';
  justify-content:'center';
  align-items:'center';
  align-Content:'center';
}
.logoname
{
  font-size: 20px;
  display:'flex';
  justify-content:'center';
  align-items:'center';
  align-Content:'center';
}